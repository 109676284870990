import kyoko from "../../img/intlspeakers/kyoko.jpg";
import veronuque from "../../img/intlspeakers/veronique.jpg";
import devid from "../../img/intlspeakers/devid.jpg";
import navin from "../../img/intlspeakers/navin.jpg";
const intsData = [
  {
    id: "1",
    image: kyoko,
    name: "Ms. Kyoko Yokosuka",
    position: "UNDP Resident Representative for Nepal",
  },
  {
    id: "2",
    image: veronuque,
    name: "Ms. Veronique Lorenzo",
    position: "Ambassador, European Union Delegation to Nepal",
  },
  {
    id: "3",
    image: devid,
    name: "Mr. Devid Sislen",
    position: " Country Director, The World Bank Nepal, Maldives and Sri Lanka",
  },
  {
    id: "4",
    image: navin,
    name: "Mr. Navin Srivastava",
    position: "India's Ambassador to Nepal",
  },
];

export { intsData };
