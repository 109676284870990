import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { TiArrowRightThick } from "react-icons/ti";

const Activities = () => {
  return (
    <>
      <Header />
      <div>
        <div className="pre_event">
          <div className="image pb-3 pb-lg-5">
            {/* <img src="./images/0.jpg" alt="" className="w-100" /> */}
            <div className="pre_acts pt-lg-5 pt-3 pb-4">
              <h2 className="mt-lg-5">Pre-Event Activities</h2>
              <div className="act_container text-white mt-lg-5">
                <div className="pe-card">
                  <div className="icon">
                    <img
                      src="./images/prev/multilateral understanding.png"
                      alt=""
                    />
                  </div>
                  <p>Multilateral Understanding Program</p>
                </div>
                <div className="pe-card">
                  <div className="icon">
                    <img
                      src="./images/prev/report and data collection.png"
                      alt=""
                    />
                  </div>
                  <p>Research and Data Collection</p>
                </div>
                <div className="pe-card">
                  <div className="icon">
                    <img src="./images/prev/policy and investment.png" alt="" />
                  </div>
                  <p>Policy and Investment Dialogue Program</p>
                </div>
                <div className="pe-card">
                  <div className="icon">
                    <img src="./images/prev/press meet.png" alt="" />
                  </div>
                  <p>Press Meet </p>
                </div>
                <div className="pe-card">
                  <div className="icon">
                    <img src="./images/prev/social media campaign.png" alt="" />
                  </div>
                  <p>Social Media Campaign</p>
                </div>
                <div className="pe-card">
                  <div className="icon">
                    <img src="./images/prev/pre event.png" alt="" />
                  </div>
                  <p>Pre-Event in all Seven Provinces</p>
                </div>
              </div>
            </div>
            <div className="act mt-lg-5">
              <h2 className="mt-lg-4">Activities of the Summit</h2>
              <div className="row w-100 mt-lg-5 ps-3 ps-lg-5 pe-lg-3">
                <div className="col-md-6">
                  <img src="./images/1.jpg" className="w-100" alt="" />
                </div>
                <div className="col-md-6">
                  <div>
                    <p>
                      <TiArrowRightThick className="oicon" />
                      <span>Inauguration Session: </span>
                      The grand inauguration, will be graced by the presence of
                      500 provincial entrepreneurs, and distinguished guests
                      including a former Prime Minister Finance Minister,
                      Governor, Chief Minister, and Ambassadors from various
                      nations.
                    </p>
                    <p>
                      <TiArrowRightThick className="oicon" />
                      <span>Udhami Pradarshani: </span>
                      An extensive showcase running from 8 a.m. to 7 p.m,
                      highlighting local production, marketing, and priority
                      industries within the province, with special emphasis on a
                      local-level industry.
                    </p>
                    <p>
                      <TiArrowRightThick className="oicon" />
                      <span>Keynote Presentation: </span>A 45-minute
                      presentation explaining the opportunities and roadmap
                      toward a prosperous province.
                    </p>
                    <p>
                      <TiArrowRightThick className="oicon" />
                      <span>Local Cultural Performances: </span>A 45-minute
                      Showcasing the rich cultural heritage of the region.
                    </p>
                    <p>
                      <TiArrowRightThick className="oicon" />
                      <span>Investment and Partnership Agreement: </span>A key
                      moment as investment and partnership agreements are
                      formally signed between the government and various
                      agencies, strengthening commitment to economic growth
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="act">
              <div className="row w-100 mt-lg-5 ps-3 ps-lg-5 pe-lg-3">
                <div className="col-md-6 ">
                  <div>
                    <p>
                      <TiArrowRightThick className="oicon" />
                      <span>Panel Discussion: </span>
                      Reflections on small, medium, and large investment
                      opportunities, alongside discussions on access to finance
                      and the government's readiness to support these ventures.
                      Acknowledging the commendable efforts at the local level
                      in promoting entrepreneurship, these discussions will also
                      offer policy and programmatic recommendations.
                    </p>
                    <p>
                      <TiArrowRightThick className="oicon" />
                      <span>Manifesto Issuance: </span>A one-hour session
                      presenting comprehensive working papers detailing the
                      regional industrial landscape, government priorities,
                      potential, and challenges.
                    </p>
                    <p>
                      <TiArrowRightThick className="oicon" />
                      <span>Talk Series: </span>Featuring insights into the
                      global business and economic patterns in Nepal, with
                      notable figures like Binod Chaudhary. Furthermore,
                      discussions will revolve around state prosperity and
                      citizens' expectations in the Niti Samvad segment,
                      including contributions from both current and former
                      Finance Minister.
                    </p>
                    <p>
                      <TiArrowRightThick className="oicon" />
                      <span>Working Paper Presentation: </span>A deep dive into
                      the potential investment sectors within National Level,
                      encompassing agriculture, tourism, sustainable energy, and
                      small and medium enterprise (SME) priority areas. These
                      sessions will also analyze the role of financial
                      institutions and local and state governments in
                      facilitating business development, promotion, and
                      expansion.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <img src="./images/2.jpg" className="w-100" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Activities;
