import React from "react";
import Headers from "./Headers";

import "./style.css";
const Header = () => {
  return (
    <div style={{ position: "sticky", top: "0", zIndex: "2" }}>
      <div className="header">
        <div className="theader">
          <Headers />
        </div>
      </div>
    </div>
  );
};

export default Header;
