import React from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import { FaRegFilePdf } from "react-icons/fa";
import "./article.css";

const ProgressReport = () => {
  return (
    <div>
      <Header />
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center">Progress Report</h2>
        </div>
      </div>
      <div className="col-md-6 offset-md-3 mt-5">
        <div className="report-card">
          <div className="d-flex">
            <div className="col-2">
              <div className="picon aicon">
                <FaRegFilePdf className="pdficon" />
              </div>
            </div>
            <div className="col-10">
              <div className="card-content p-3">
                {/* <span>25 October, 2024</span> */}
                <h4>Progress Report 2023</h4>
                <span>
                  "Current Economic Challenges and Roadmap for Shared
                  Prosperity"
                </span>
                <a href="./images/progressreport.pdf" target="__blank">
                  <button className="view aview">View</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProgressReport;
