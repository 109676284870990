import prakash from "../../img/organizer/prakash.jpg";
import pawan from "../../img/organizer/pawan kumar golyan.jpg";
import upendra from "../../img/organizer/upendra.png";
import bishalsir from "../../img/organizer/bishalsir.jpg";
import roshan from "../../img/organizer/roshan koirala.jpg";
import rajesh from "../../img/organizer/rajesh upadhaya.jpg";
import kush from "../../img/organizer/kush prasad mally.jpg";
import bharat from "../../img/organizer/bharat dhakal.jpg";
import naresh from "../../img/organizer/naresh prasad sharma.jpg";
const ocData = [
  {
    id: "1",
    image: prakash,
    name: "Dr. Prakash Kumar Shrestha",
    position: "Member, NPC (Advisor)",
  },
  {
    id: "2",
    image: pawan,
    name: "Mr. Pawan Kumar Golyan",
    position: "President Emeritus of CBFIN (Advisor)",
  },
  {
    id: "3",
    image: upendra,
    name: "Mr. Upendra Poudel",
    position: "  President, CBFIN (Advisor)",
  },
  {
    id: "4",
    image: bishalsir,
    name: "Bishal Gaire",
    position: "Executive Chairperson, NBI (Summit Coordinator)",
  },
  {
    id: "5",
    image: roshan,
    name: "Roshan Koirala",
    position: "Deputy Director General, CBFIN",
  },
  {
    id: "6",
    image: rajesh,
    name: "Rajesh Upadhya",
    position: "Senior Vice President, CBFIN",
  },
  {
    id: "7",
    image: kush,
    name: "Kush Prasad Mally",
    position: "Vice-President, CBFIN",
  },
  {
    id: "8",
    image: bharat,
    name: "Bharat Dhakal",
    position: "Secretary, CBFIN",
  },
  {
    id: "9",
    image: naresh,
    name: "Naresh Prasad Shrestha",
    position: "Director, NBI",
  },
];

export { ocData };
