import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./style.css";
import "../style.css";

const LandScape = () => {
  return (
    <>
      <Header />
      <div className="about">
        <div className="about-background">
          <img src="./images/drone1.jpg" className="w-100" alt="..." />
          <h2>Business Scenario in Nepal</h2>
        </div>
        <div className=" pt-md-4">
          <div className="col-md-10 offset-md-1">
            <div className="row">
              <div className="col-md-7 abouts">
                <div className="p-4">
                  <h2>Business Scenario in Nepal</h2>
                  <p>
                    <b>
                      Nepal is an emerging economy with a growing population,
                      and there are numerous opportunities for businesses to
                      thrive in the country.
                    </b>
                    <br /> <br />
                    Nepal is a country with great potential for business growth
                    and development. The Business ecosystem in Nepal has been
                    rapidly evolving over the past few years. With the right
                    strategies and policies, businesses can thrive in this
                    market. The business environment in Nepal is at the same
                    time challenging due to a number of factors, including
                    political instability, poor infrastructure, and a lack of
                    access to financing. <br /> <br />
                    Nepal, nestled in the Himalayas, is a landlocked country in
                    South Asia renowned for its rich tapestry of diverse
                    geography, climate, population, and biodiversity. This
                    remarkable diversity, however, also places Nepal among the
                    world&#39;s most vulnerable nations to natural disasters.{" "}
                    <br /> <br />
                    Over recent decades, Nepal has achieved considerable
                    progress on human development, poverty reduction, and
                    economic growth. These advancements have set the stage for
                    Nepal&#39;s anticipated graduation to Middle-Income Country
                    status by 2026. <br />
                  </p>
                </div>
              </div>
              <div className="col-md-5 pt-md-5 mt-lg-5 land p-3 p-md-0">
                <img src="./images/land.jpg" alt="" className="w-100 " />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LandScape;
