import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

const Partners = () => {
  return (
    <div className="partners pt-4 pb-4">
      <h3 className="pb-5 mb-lg-4">Partners</h3>
      {/* <div className="partners-head pb-3 pb-md-5">
        <h3>Joint Initiative</h3>
      </div>
      <div className="row w-100">
        <div className="col-md-4 mb-5 mb-md-0 partner ps-5 pe-5 ps-md-5 pe-md-5">
          <center>
            <img src="./images/partners/organizer/sarkar.jpg" alt="..." />
            <br />
            <span
              style={{
                color: "#0b76bc",
              }}
            >
              Ministry of Industry,Commerce & Supply
            </span>
          </center>
        </div>
        <div className="col-md-4 mb-5 mb-md-0 partner">
          <center>
            <img src="./images/partners/organizer/cbfin.png" alt="..." />
          </center>
        </div>
        <div className="col-md-4 mb-3">
          <center>
            <img
              src="./images/partners/organizer/nbi.jpg"
              className="w-100 ps-5 pe-5 nbi"
              alt="..."
            />
          </center>
        </div>
      </div> */}
      <div className="joint">
        <h4>Joint Initiative</h4>
        <div className="joints">
          <div className="part_card sarkar">
            <img src="./images/partners/organizer/sarkar.jpg" alt="" />
            <p>
              Ministry of Industry,Commerce <br /> & Supply (GON)
            </p>
          </div>
          <div className="line"></div>
          <div className="part_card sarkar">
            <img src="./images/partners/organizer/sarkar.jpg" alt="" />
            <p>
              National Planning Commission <br /> (GON)
            </p>
          </div>
          <div className="line"></div>
          <div className="part_card cbfin">
            <img src="./images/partners/organizer/cbfin.png" alt="" />
            <p className="pt-lg-4">
              Confederation of Banks & <br /> Financial Institutions Nepal
              (CBFIN)
            </p>
          </div>
          <div className="line"></div>
          <div className="part_card nbi">
            <img
              src="./images/partners/organizer/nbi.jpg"
              alt=""
              className="mt-lg-2"
            />
            <p className="mt-lg-3">
              Nepal Business Institute <br /> (NBI)
            </p>
          </div>
        </div>
      </div>
      <div className="powered_ips mt-3 mt-lg-4">
        <div className="poweredBy">
          <h4 className="mb-2">Powered By</h4>
          <div className="powered_card">
            <img src="./images/partners/rbb.jpg" className="rbb" alt="" />
            <p>
              Rastriya Banijya Bank <br /> Limited (RBBL)
            </p>
          </div>
        </div>
        <div className="poweredBy">
          <h4 className="mb-2">IPS Partner</h4>
          <div className="powered_card">
            <img
              src="./images/partners/worldlink.jpg"
              className="world"
              alt=""
            />
            <p>
              WorldLink <br />
              Communications Ltd.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
