import kpoli from "../../img/distinguished/kpoli1.jpg";
import prachanda from "../../img/distinguished/prachanda.jpg";
import bishnu from "../../img/distinguished/Bishnu_FM.jpg";
import damodar from "../../img/distinguished/damodar.jpg";
import prithivi from "../../img/distinguished/prithivi subba.jpg";
import ramnath from "../../img/distinguished/ramnath.jpg";
import bidhya from "../../img/distinguished/bidya.jpg";
const distData = [
  {
    id: "1",
    image: kpoli,
    name: "Rt. Hon. K.P Sharma Oli",
    position: "Prime Minister of Nepal",
    type: "Chief Guest",
  },
  {
    id: "2",
    image: prachanda,
    name: "Hon. Pushpa Kamal Dahal",
    position: "Former Prime Minister of Nepal",
  },
  {
    id: "3",
    image: bishnu,
    name: "Hon. Bishnu Prashad Paudel",
    position: "Minister For Finance (GoN)",
  },

  {
    id: "4",
    image: damodar,
    name: "Hon. Damodar Bhandari",
    position: " Minister For Industry, Commerce and Supplies (GoN)",
  },
  {
    id: "5",
    image: prithivi,
    name: "Hon. Prithivi Subba Gurung",
    position: " Minister For Communications, and Information Technology",
  },
  {
    id: "6",
    image: ramnath,
    name: "Hon. Ramnath Adhikari ",
    position: " Minister For Agriculture and Livestock Development",
  },
  {
    id: "7",
    image: bidhya,
    name: "Hon. Bidhya Bhattarai",
    position: " Minister For Education, Science and Technology",
  },
];

export { distData };
