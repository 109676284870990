import React from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import "./article.css";

const Article = () => {
  return (
    <div>
      <Header />
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center">Article Competition</h2>
        </div>
      </div>
      <div className="container about pt-2 pt-md-5 pb-2 pb-md-5">
        <div className="abouts pt-lg-5 pb-lg-5">
          <div className="col-lg-5 mt-md-5">
            <div className="about-img">
              <img
                className="w-100"
                src="./images/article.jpg"
                alt="Second slide"
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="about-content">
              <p className="pt-lg-5">
                वर्तमान आर्थिक संकट समाधान र यसको रोडम्यापबारे राष्ट्रियस्तरमा
                बहस पैरवी गर्दै समाधानको निचोड निकाल्नका लागि{" "}
                <strong>Nationawide Journalist Fellowship Program 2023</strong>
                को आयोजना गरिएको हो । नेपाल बिजनेस समिटको पुर्वसन्ध्यामा{" "}
                <strong>
                  बैंक तथा वित्तीय संस्था परिसंघ नेपाल (CBFIN), नेपाल बिजनेस
                  इन्स्टिच्युट (NBI) र नेपाल पत्रकार महासंघ (FNJ)
                </strong>{" "}
                सँगको सहकार्यमा यो कार्यत्रफ्म शुरु भएको छ । वर्तमान{" "}
                <strong>आर्थिक परिदृश्य र समृद्धिको मार्गचित्र</strong> विषयमा
                २५०० शब्द नबढ्ने गरी देशभरका पत्रपत्रिका, रेडियो, टेलिभिजन र
                अनलाइन सञ्चारमाध्यममा कार्यरत पत्रकारहरुले लेख पठाउन सक्ने छन्।
                प्राप्त लेखहरुमध्ये उत्कृष्ट २५ लेखहरु पुरस्कृत हुने छन्।
              </p>
            </div>
          </div>
        </div>
        <div
          className="row pt-3  pt-lg-5 pb-3"
          style={{ backgroundColor: "#f2f2f2" }}
        >
          <div className="col-md-4 ">
            <div className="goals p-4 about-goals">
              <h3 className="pb-2">प्रतियोगीताको उद्देश्यहरु</h3>
              <p>
                - कोभिड–१९ पछिको अर्थतन्त्रका प्रमुख चुनौती, अवसर र भावी
                रोडम्यापबारे युवाहरुको सृजनशील क्षमताबाट प्राप्त लेखलाई
                प्रोत्साहन गरी राष्ट्रिय अर्थतन्त्रको सबलीकरण गर्ने उद्देश्य
                बोकेको छ ।
              </p>
              <p>
                - प्राप्त सुझावहरु अनुसार अर्थतन्त्रले भोगिरहेका वर्तमान
                चुनौतीहरुको समाधानका लागि सरकारलाई सुझावहरु दिनुका साथैठुला
                उद्योगको संरक्षण, साना, मभफैला तथा स्टार्टअप उद्यमहरुलाई
                प्रोत्साहन गर्ने उद्देश्य राखेको छ ।
              </p>
              <p>
                - स्थानीय, प्रदेश र संघमा नयाँ सरकार बनेकाले सरकारलाई अल्पकालीन,
                मध्यकालीन र दिर्घकालीनरुपमा आर्थिक सुधारका उपायबारे भिजन
                डकुमेन्ट पेश गर्ने उद्देश्य बोकेको छ ।
              </p>
              <p>
                - पत्रकारहरुलाई आर्थिक मुद्धामा लेख र समाचार लेख्न प्रोत्साहन
                गर्ने ।
              </p>
            </div>
          </div>
          <div className="col-md-8">
            <div className="objectives p-4">
              <h3 className="pb-2">प्रतियोगीतामा सहभागीहरुको योग्यता</h3>
              <p>
                कुनै पनि सञ्चारमाध्यममा कार्यरत पत्रकारहरुलेयो प्रतियोगीतामा भाग
                लिन सक्ने छन्। आपफ् ुकार्यरत सञ्चार माध्यमको पुष्टि हुने पत्रकार
                परिचय पत्र, नियुक्ति पत्र वा मिडिया हाउसको पत्र (कुनै एक) सहित
                कतै पनि प्रकाशित नभएको खोजमुलक र तथ्यपरक लेख अनलाइनमार्फत
                तोकिएको मितिभित्र पठाउनु पर्नेछ ।
              </p>
              <h3 className="pb-2 pt-4">छनौट प्रक्रिया</h3>
              <p>
                अनलाइन माध्यमबाट प्राप्त लेखहरुलाई ५ सदस्यीय छनौट समितिले
                विभिन्न मापदण्डका आधारमा छनौट गर्ने छ र छनौट भएका उत्कृष्ट
                लेखहरुलाई नेपाल बिजनेस समिटको समुद्घाटन सत्रमा सम्माननीय
                प्रधानमन्त्रीज्यूको हातबाट पुरस्कृत गरिने छ । छनौटका आधारहरु
                निम्नलिखित रहेका छन्।
              </p>
              <p>क) लेखको मौलिकता</p>
              <p>ख) लेखको तथ्यपरकता</p>
              <p>ग) लेखले औल्याएका भावी मार्गदर्शन</p>
            </div>
          </div>
        </div>
        {/* mission vision goals */}
        <div className="vmg pt-md-4">
          <div className="mission-vision-goals container pt-md-5 pb-md-5">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12 mb-4">
                <div className="vision pt-4 mb-4">
                  <div className="vmg-head">
                    <h4>प्रथम पुरस्कार</h4>
                  </div>
                  <div className="vmg-text ">
                    <p>
                      - नगद रु. १ लाख रुपैया । <br />
                      - बिजनेश समिटको समुद्घाटन समारोहमा प्रमाणपत्र, मेडलसहित
                      सम्मान तथा पुरस्कार वितरण । <br />
                      - लेखलाई पुस्तककोरुपमा प्रकाशन । <br />
                      - राष्टियस्तरको अनलाइनमा लेख प्रकाशन <br />
                      -नेपाल बिजनेश समिटमा निःशुल्क रजिष्ट्रेशन
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="mission  pt-4 ">
                  <div className="vmg-head">
                    <h4>द्धितिय पुरस्कार</h4>
                  </div>
                  <div className="vmg-text">
                    <p>
                      - नगद रु. ७५ हजार रुपैया । <br />- बिजनेश समिटको समुद्घाटन
                      समारोहमा प्रमाणपत्र, मेडलसहित सम्मान तथा पुरस्कार वितरण ।{" "}
                      <br />- लेखलाई पुस्तककोरुपमा प्रकाशन । <br />-
                      राष्टियस्तरको अनलाइनमा लेख प्रकाशन । <br /> - नेपाल बिजनेश
                      समिटमा निः शुल्क रजिष्ट्रेशन । <br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mt-4 mt-md-0 mb-4">
                <div className="goals  pt-4 ">
                  <div className="vmg-head">
                    <h4>तृतीय पुरस्कार</h4>
                  </div>
                  <div className="vmg-text">
                    <p>
                      - नगद रु. ५० हजार रुपैया । <br />
                      - बिजनेश समिटको समुद्घाटन समारोहमा प्रमाणपत्र, मेडलसहित
                      सम्मान तथा पुरस्कार वितरण । <br />
                      - लेखलाई पुस्तककोरुपमा प्रकाशन । <br />
                      - राष्टियस्तरको अनलाइनमा लेख प्रकाशन । <br />- नेपाल
                      बिजनेश समिटमा निः शुल्क रजिष्ट्रेशन ।
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="vision pt-4 mb-4">
                  <div className="vmg-head">
                    <h4>चौथो पुरस्कार</h4>
                  </div>
                  <div className="vmg-text ">
                    <p>
                      - नगद रु. २५ हजार रुपैया ।<br />
                      - बिजनेश समिटको समुद्घाटन समारोहमा प्रमाणपत्र, मेडलसहित
                      सम्मान तथा पुरस्कार वितरण ।<br />
                      - लेखलाई पुस्तककोरुपमा प्रकाशन ।<br />
                      - राष्टियस्तरको अनलाइनमा लेख प्रकाशन ।<br />
                      -नेपाल बिजनेश समिटमा निःशुल्क रजिष्ट्रेशन
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="vision pt-4 mb-4">
                  <div className="vmg-head">
                    <h4>पाँचौ पुरस्कार</h4>
                  </div>
                  <div className="vmg-text ">
                    <p>
                      - नगद रु. २५ हजार रुपैया ।<br />
                      - बिजनेश समिटको समुद्घाटन समारोहमा प्रमाणपत्र, मेडलसहित
                      सम्मान तथा पुरस्कार वितरण ।<br />
                      - लेखलाई पुस्तककोरुपमा प्रकाशन ।<br />
                      - राष्टियस्तरको अनलाइनमा लेख प्रकाशन ।<br />
                      -नेपाल बिजनेश समिटमा निःशुल्क रजिष्ट्रेशन
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mt-4 mt-md-0">
                <div className="goals  pt-4 ">
                  <div className="vmg-head">
                    <h4>उत्कृष्ट १० प्रतियोगी पुरस्कार</h4>
                  </div>
                  <div className="vmg-text">
                    <p>
                      - नगद रु. १० हजार रुपैया । <br />
                      - बिजनेश समिटमा प्रमाणपत्र वितरण । <br />
                      - लेखलाई पुस्तककोरुपमा प्रकाशन । <br />- राष्टियस्तरको
                      अनलाइनमा लेख प्रकाशन ।
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* karya talika */}
        <div>
          <div className="ahead pb-3">
            <center>
              <h2>प्रतियोगीताको कार्यतालिका</h2>
            </center>
          </div>
          <div>
            <table className="table table-bordered border-primary">
              <thead>
                <tr>
                  <th scope="col">क्रस</th>
                  <th scope="col">बिषय</th>
                  <th scope="col">समय</th>
                  <th scope="col">कैफियत</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">१.</th>
                  <td>आवेदन खुल्ला</td>
                  <td>मिति २०७९ फागुन २२ गते</td>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row">२.</th>
                  <td>आवेदन दिने अन्तिम मिति</td>
                  <td>मिति २०७९ चैत्र १३ गते</td>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row">३.</th>
                  <td>नतिजा प्रकाशन</td>
                  <td>मिति २०७९ चैत्र मसन्ता भित्र</td>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row">४.</th>
                  <td>सम्मान तथा पुरस्कार बितरण</td>
                  <td>मिति २०८० वैशाख १० गते</td>
                  <td>समुद्घाटन सत्र</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="apply-button pt-5">
          <center>
            {" "}
            <h4>Registration for article competition is now closed!</h4>
          </center>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Article;
