import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "../article.css";

const Faq = () => {
  return (
    <div>
      <Header />
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center">FAQ's</h2>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Faq;
