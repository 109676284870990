import React from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import "./article.css";

const News = () => {
  return (
    <div>
      <Header />
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center">News</h2>
        </div>
      </div>
      <div className="news-room pt-5 ps-md-0 ps-3 pb-5">
        <div className="row w-100 ps-md-5 pe-md-5 pt-5">
          <div className="col-md-4 mb-4">
            <div className="news-card">
              <div className="news-image">
                <img
                  src="./images/news0.jpg"
                  alt="..."
                  height={270}
                  className="w-100"
                />
              </div>
              <div className="news-content">
                <p className="date">
                  <i>२०८० वैशाख १८ गते </i>
                </p>
                <h6 style={{ fontWeight: "bold" }}>
                  २४ वैशाखमा ‘नेपाल बिजनेस समिट २०२३’ आयोजना हुने
                </h6>
                <p className="text">
                  १८ वैशाख, काठमाडौं । नेपाल सरकार र निजी क्षेत्रको साझेदारीमा
                  ‘नेपाल बिजनेस समिट २०२३’ आयोजना हुने भएको छ । २४ वैशाखमा संघीय
                  सरकारको उद्योग, बाणिज्य तथा आपूर्ति मन्त्रालय, बैंक तथा
                  वित्तीय संस्था परिसंघ नेपाल (सीबीफीन) तथा ...
                </p>
                <a
                  href="https://www.onlinekhabar.com/2023/05/1300593?fbclid=IwAR1RAv-uSyp5C7O6MeEaAFw5-V7wdRltboKx0USQbT1KdUjbn6tfIUIYuSs"
                  target="__blank"
                  className="text-decoration-none"
                >
                  <h5>Read More</h5>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="news-card">
              <div className="news-image">
                <img
                  src="./images/newss.jpg"
                  alt="..."
                  height={270}
                  className="w-100"
                />
              </div>
              <div className="news-content">
                <p className="date">
                  <i>६ पुष २०७९</i>
                </p>
                <h6 style={{ fontWeight: "bold" }}>
                  ‘नेपाल व्यवसाय शिखर सम्मेलन २०२३’ आयोजना हुने
                </h6>
                <p className="text">
                  काठमाडौं । नेपाल सरकार, बैंक तथा वित्तीय संस्था परिसंघ नेपाल र
                  नेपाल बिजनेस इन्स्टिच्युटको संयुक्त आयोजना र संघीय सरकारका
                  विभिन्न मन्त्रालयहरुको साझेदारीतामा नेपाल व्यवसाय शिखर सम्मेलन
                  २०२३ आयोजना हुने...
                </p>
                <a
                  href="https://clickmandu.com/2022/12/225401.html"
                  target="__blank"
                  className="text-decoration-none"
                >
                  <h5>Read More</h5>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="news-card">
              <div className="news-image">
                <img
                  src="./images/newsr.webp"
                  alt="..."
                  height={270}
                  className="w-100"
                />
              </div>
              <div className="news-content">
                <p className="date">
                  <i>२९ फागुन २०७९</i>
                </p>
                <h6 style={{ fontWeight: "bold" }}>
                  बिजनेस समिटको टाइटल स्पोन्सरमा वर्ल्ड लिंक कम्युनिकेसन
                </h6>
                <p className="text">
                  काठमाडौँ । नेपाल बिजनेस समिट २०२३ को टाइटल स्पोन्सरमा वर्ल्ड
                  लिंक कम्युनिकेशन रहने भएको छ । नेपाल सरकार, बैंक तथा वित्तीय
                  संस्था परिसंघ नेपाल र नेपाल बिजनेस इन्स्टिच्युटको संयुक्त
                  आयोजनामा वैशाखको दोस्रो हप्ता आयोजना हुने बिजनेस समिटमा ...
                </p>
                <a
                  href="https://www.nepalbusiness.com/News/Details/2355?fbclid=IwAR3l77bLWgG_4pAkBzosYFZGnK2Nz_4jGZXHivGrN7DG5b0qAE4NDz5-y3U"
                  target="__blank"
                  className="text-decoration-none"
                >
                  <h5>Read More</h5>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="news-card">
              <div className="news-image">
                <img
                  src="./images/newss1.jpg"
                  alt="..."
                  height={270}
                  className="w-100"
                />
              </div>
              <div className="news-content">
                <p className="date">
                  <i>Dec 22, 2022</i>
                </p>
                <h6 style={{ fontWeight: "bold" }}>
                  Nepal Business Summit 2023 to be organised
                </h6>
                <p className="text">
                  The Nepal government, Confederation of Banks and Financial
                  Institutions Nepal (CBFIN) and Nepal Business Institute (NBI)
                  are jointly organising Nepal Business Summit 2023....
                </p>
                <a
                  href="https://thehimalayantimes.com/business/nepal-business-summit-2023-to-be-organised"
                  target="__blank"
                  className="text-decoration-none"
                >
                  <h5>Read More</h5>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default News;
