import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./style.css";
import { Link } from "react-router-dom";

const Headerf = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="header-first">
      <div className="first">
        <ul>
          <Link to="/article-competition" className="text-decoration-none">
            <li>Article Competition</li>
          </Link>
          <Link to="/register" className="register text-decoration-none">
            <li>Register</li>
          </Link>

          <Link to="/contact-us" className="mnone text-decoration-none">
            <li>Contact Us</li>
          </Link>
          <div className="col-md-1"></div>
        </ul>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Organizer Introduction/Experience</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            The Nepal Business Institute (NBI) is an academic organization that
            takes the lead in fostering entrepreneurship and business
            environment at the national level. NBI is founded with the core
            motto &quot;
            <b>Learning Business, Inspiring Growth</b>&quot; and strives to plan
            Nepal&#39;s economic development through entrepreneurship and
            innovation.
          </p>
          <h5 style={{ color: "#284e9c" }}>Major NBI Programs</h5>
          <ul>
            <li>
              Operation of colleges and universities with National and
              International affiliations.
            </li>
            <li>Skill Lab Program.</li>
            <li>
              Conducting Summit, conferences, contests and award programs.
            </li>
            <li>
              Start-up Fund Program for promotion of start-up enterprises.
            </li>
            <li>Business Incubation Program.</li>
            <li>Inter-Province and Inter-National industrial visits.</li>
            <li>Conducting business dialogue and bootcamp programs.</li>
            <li>
              Organizing Fintech Program to increase financial literacy and
              access to finance.
            </li>
            <li>Managing Nepal Business School Mentor Program.</li>
            <li>
              Delivering training on fostering entrepreneurship using
              local tools and resources.
            </li>
            <li>Exporting Nepali Product.</li>
          </ul>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Headerf;
