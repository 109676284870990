import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "../article.css";

const Agenda = () => {
  return (
    <div className="inps">
      <Header />
      <div className="about-head p-3 p-md-5">
        <div className="container">
          <h2 className="text-center">Primary Agenda of The Summit</h2>
        </div>
      </div>
      <div className="bgs pt-5">
        <div className="container pt-lg-4">
          <div className="hex-row">
            <div className="hexagon agro-businesss">
              <div className="hex-content">
                <h3 className="text">Agro-Business</h3>
                <img src="./images/focus/agrobusiness.png" alt="" />
              </div>
            </div>
            <div className="hexagon digital-techs">
              <div className="hex-content">
                <h3 className="text">
                  Digitalization <br /> Technology
                </h3>
                <img src="./images/focus/technology.png" alt="" />
              </div>
            </div>
          </div>
          <div className="hex-row">
            <div className="hexagon tourisms">
              <div className="hex-content">
                <h3 className="text">Tourism</h3>
                <img src="./images/focus/tourism.png" alt="" />
              </div>
            </div>
            <div className="hexagon focus-centers">
              <div className="hex-content">
                <h2 className="texts">
                  FOCUSED <br /> AREAS OF <br /> THE SUMMIT
                </h2>
              </div>
            </div>
            <div className="hexagon sme-businesss">
              <div className="hex-content">
                <h3 className="text">
                  SME <br /> Business
                </h3>
                <img src="./images/focus/sme.png" alt="" />
              </div>
            </div>
          </div>
          <div className="hex-row">
            <div className="hexagon potential-investments">
              <div className="hex-content">
                <h3 className="text">
                  Potential <br /> Investment Sector
                </h3>
                <img src="./images/focus/potential.png" alt="" />
              </div>
            </div>
            <div className="hexagon startup-innovations">
              <div className="hex-content">
                <h3 className="text">
                  Startup <br /> and Innovation
                </h3>
                <img src="./images/focus/startup.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="button_content">
          Sustainable Development & Prosperity
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Agenda;
