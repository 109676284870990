import React from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import "./article.css";

const Organizer = () => {
  return (
    <div>
      <Header />
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center">Organizers</h2>
        </div>
      </div>
      <div
        className="moder distinguished-guest pt-3 pb-md-5 pt-md-4 pb-4"
        id="organizer"
      >
        {/*  */}
      </div>
      <Footer />
    </div>
  );
};

export default Organizer;
