import React from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "./article.css";
import { useParams } from "react-router-dom";
import { distData } from "../components/constants/distData";
import { intsData } from "../components/constants/intsData";
import { spkData } from "../components/constants/spkData";
import { ocData } from "../components/constants/ocData";

const AllSpeaker = () => {
  const params = useParams();
  console.log(params);
  return (
    <div>
      <Header />
      <div className="allSpeakers">
        <div className="containers">
          <div className="head pt-3 pb-3 pt-lg-5 pb-lg-5 mb-4">
            <h3>All Speakers</h3>
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey={params.key}>
            <Col>
              <Nav variant="pills" className="w-full mb-5">
                <Nav.Item>
                  <Nav.Link
                    // href="/distinguished-guest"
                    eventKey="distinguished-guests"
                  >
                    Distinguished Guest
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    // href="/international-speakers"
                    eventKey="international-speakers"
                  >
                    International Speakers
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="speakers">Speakers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    // href="/organizing-committee"
                    eventKey="organizing-committee"
                  >
                    organizing committee
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12} className="distinguished-guest">
              <Tab.Content>
                <Tab.Pane eventKey="distinguished-guests">
                  <div className="guest pb-4">
                    {distData.map((d) => (
                      <div
                        key={d.id}
                        className="col-lg-3 col-md-6 mt-4 col-sm-12"
                      >
                        <div className="guest-card">
                          <div className="card-img">
                            <img src={d.image} alt="...." className="w-100" />
                          </div>
                          <div className="card-title">
                            <div className="card-name pt-2 ">
                              <h4>{d.name}</h4>
                            </div>
                            <div className="card-position">
                              <p className="ps-lg-1 pe-lg-1">
                                {d.position} <br />
                                <span style={{ color: "#000" }}>{d.type} </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="international-speakers">
                  <div className="guest pb-4">
                    {intsData.map((d) => (
                      <div
                        key={d.id}
                        className="col-lg-3 mt-4 col-md-6 col-sm-12"
                      >
                        <div className="guest-card">
                          <div className="card-img">
                            <img src={d.image} alt="...." className="w-100" />
                          </div>
                          <div className="card-title">
                            <div className="card-name pt-2 ">
                              <h4>{d.name}</h4>
                            </div>
                            <div className="card-position">
                              <p className="ps-lg-1 pe-lg-1">
                                {d.position} <br />
                                <span style={{ color: "#000" }}>{d.type} </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="speakers">
                  <div className="guest pb-4">
                    {spkData.map((d) => (
                      <div
                        key={d.id}
                        className="col-lg-3 col-md-6 col-sm-12 mt-4"
                      >
                        <div className="guest-card">
                          <div className="card-img">
                            <img src={d.image} alt="...." className="w-100" />
                          </div>
                          <div className="card-title">
                            <div className="card-name pt-2 ">
                              <h4>{d.name}</h4>
                            </div>
                            <div className="card-position">
                              <p className="ps-lg-1 pe-lg-1">
                                {d.position} <br />
                                <span style={{ color: "#000" }}>{d.type} </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="organizing-committee">
                  <div className="guest pb-4">
                    {ocData.map((d) => (
                      <div
                        key={d.id}
                        className="col-lg-3 col-md-6 col-sm-12 mt-4"
                      >
                        <div className="guest-card">
                          <div className="card-img">
                            <img src={d.image} alt="...." className="w-100" />
                          </div>
                          <div className="card-title">
                            <div className="card-name pt-2 ">
                              <h4>{d.name}</h4>
                            </div>
                            <div className="card-position">
                              <p className="ps-lg-1 pe-lg-1">
                                {d.position} <br />
                                <span style={{ color: "#000" }}>{d.type} </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Tab.Container>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AllSpeaker;
